module.exports = new Promise(resolve => {
      const remoteUrl = globalThis.import_meta_env.REACT_APP_BLINK_AUTH_URL
      const script = document.createElement('script')
      script.src = remoteUrl
      script.onload = () => {
        const proxy = {
          get: (request) => window.blinkAuth.get(request),
          init: (arg) => {
            try {
              return window.blinkAuth.init(arg)
            } catch(e) {
              console.log('remote container already initialized')
              console.warn('Failed to load ' + variable)
            }
          }
        }
        resolve(proxy)
      }
      document.head.appendChild(script);
    })
    ;